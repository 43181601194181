// import appleImage from "common/assets/image/interior/flavorick_apple.png"

import { Link } from "react-router-dom";

// import playImage from "common/assets/image/interior/Flavorick_play.png"
function Download2() {
    // console.log(playImage)
    return ( 
        <>
        <div className="container p-4" id="download mt-5" style={{marginTop:"40px"}}>
           <div className="row">
            <div className="col-md-6 d-flex mt-sm-5">
               <Link to="https://play.google.com/store/apps/details?id=com.medocly.patients" target="_blank"><img src="../playStore.png" style={{height:"160px"}}/></Link> 
                <div className="d-flex align-item-center text-dark mt-xl-1 mt-sm-1 ms-2 fw-bold fs-2">Scan or click to download Medocly on Google Play Store</div>
            </div>
            <div className="col-md-6 d-flex mt-5">
            <Link to="https://apps.apple.com/in/app/medocly-patient/id6477620364" target="_blank"><img src="../appStore.png" style={{height:"160px"}}/></Link>
            <div className="d-flex align-item-center mt-xl-1 text-dark mt-sm-1 ms-2 fw-bold fs-2">Scan or click to download Medocly on App  Store</div>
            </div>
           </div>
        </div>
        </>
     );
}

export default Download2;