import React from "react";

export default function AboutApp() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg mt-5">
        <div className="container">
          
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <span className="text-uppercase color-secondary sub-title">
                About us
              </span>
              <h2>Medocly : Your health,Your Doctor</h2>
              <p className="mb-4 lh-190">
                Our service is a comprehensive healthcare platform that connects patients with a wide network of
                doctors and facilitates convenient access to quality care. We understand that finding the right doctor
                and managing healthcare can be challenging and time-consuming, so we've built a solution that
                simplifies the process and puts the patient's needs at the forefront
              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle mr-3 text-light" style={{background:"#E8510B"}}>
                        <span className="ti-check "></span>
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="mb-0">Step 1 - Through our user-friendly website and mobile application, patients can easily search for nearby doctors
                        based on their location and specific medical needs.</p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Step 2 - Each doctor has a detailed profile that highlights their qualifications, experience, areas of expertise, and
affiliations along with their ratings and reviews to make a informed decision.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Step 3 - Once patients have identified a suitable doctor, our platform enables them to book consultation
appointments conveniently. Our intuitive booking system displays available time slots, allowing patients
to choose the most convenient date and time for their visit. The process is seamless and eliminates the
need for time-consuming phone calls or in-person visits to schedule appointments
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Step 4 - Patients can visit the designated
healthcare facility at their scheduled appointment time, ensuring a smooth and efficient experience.
                      </p>
                    </div>
                  </div>
                </li>
                {/* <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                        Collaboratively predominate vertical manufactured
                      </p>
                    </div>
                  </div>
                </li> */}
              </ul>
              <p className="mb-4 lh-190 mt-3">
              We understand that the healthcare journey can often involve long wait times and crowded waiting
rooms. To address this, we offer fast-track services where available, allowing patients to prioritize their
consultations and skip the usual queues. This feature saves time and enhances the overall patient
experience.
              </p>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/aboutUs.png"
                  width="500"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
