import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Best features
                </span>
                <h2>Easy Process with Best Features</h2>
                <p>
                See some of our inside out features which makes our application so much powerful and useful for both patients and doctors
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-face-smile"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Convenient Appointment Booking</h5>
                  <p className="mb-0">
                  Easily secure appointments from available time slots with just a few taps.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-vector"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>On-Site Consultations</h5>
                  <p className="mb-0">
                  Patients always have the option to visit the clinic or hospital for their consultations
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-headphone-alt"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Home Nursing Care Services</h5>
                  <p className="mb-0">
                  Medocly also offers the convenience of booking nursing care services at home.
                  </p>
                </div>
                
                
              </div>
              <div className="d-flex align-items-start mb-5 mt-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-vector"></span>
                  </div>
                </div>
                <div className="icon-text mt-2">
                  <h5>Secure Medical Records Storage</h5>
                  <p className="mb-0">
                  We allows patients to store and access their past diagnosis reports conveniently.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-media-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Timely Consultation Reminders</h5>
                  <p className="mb-0">
                  Our application sends timely consultation reminders and notifications to stay organized.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Fast-Track Services to Skip Crowds</h5>
                  <p className="mb-0">
                  For patients seeking to minimize waiting times, our application offers fast-track services where available.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-palette"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Comprehensive Doctor Directory</h5>
                  <p className="mb-0">
                  Nearby doctors with diversified specialties and
relevant information
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5 mt-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text mt-2">
                  <h5>Doctor Reviews and Ratings</h5>
                  <p className="mb-0">
                  Make informed decisions about the doctors by feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
