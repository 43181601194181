import { Faqs } from '../../../src/data'

export default function AccordionWithImg() {
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div id="accordion-1" className="accordion accordion-faq pb-100">
                
              {Faqs.map(i=>(
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-2"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-2"
                    aria-expanded="false"
                    aria-controls="collapse-1-2"
                  >
                    <h6 className="mb-0">
                      <span className="ti-gallery mr-3"></span>{i.question}
                    </h6>
                  </div>
                  <div
                    id="collapse-1-2"
                    className="collapse"
                    aria-labelledby="heading-1-2"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                      {i.answer}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
                

                
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/frame1.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
