import React from "react";

export default function AboutApp() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-12">
              <span className="text-uppercase color-secondary sub-title">
              
              </span>
              <h2>Specialized Managed
Home Care Plan</h2>
              <p className="mb-4 lh-190">
              Medocly Nursing care at home services in jammu,jammu and kashmir are aimed at managing patients at
home in the best possible manner so as to enable optimal recovery and return to normal life. Our team
of skilled home care nursing staff and caretakers has over the years built strong capabilities in postoperative recovery of patients, management of bed-ridden patients, Post Hospital Care, Elderly
Care/Geriatric Care at home. Our team is capable of managing a wide variety of patient requirements,
including:

              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle mr-3 text-light" style={{background:"#E8510B"}}>
                        <span className="ti-check "></span>
                      </div>
                    </div>
                    <div className="">
                      <p className="mb-0">Wound management</p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      IV infusion therapy
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Bed sore care
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Fluid management
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Administering oxygen
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Nebulization
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      BiPAP/CPAP management
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Stoma care
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Tracheostomy care
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      (Ryle’s Tube, PEG) fluid
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle text-light mr-3" style={{background:"#E8510B"}}>
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                      Cretical care management, etc.
                      </p>
                    </div>
                  </div>
                </li>
                {/* <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0">
                        Collaboratively predominate vertical manufactured
                      </p>
                    </div>
                  </div>
                </li> */}
              </ul>
              
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}
