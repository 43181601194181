import React from "react";
import Counter from "../../components/counter/Counter";
import { Link } from "react-router-dom";

export default function HeroTwo() {
  return (
    <>
      <section
        className="hero-section pt-100 background-img"
        style={{
          background:

            "url('assets/img/doctors.jpeg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left text-white mt-5">
                <span className="text-uppercase h6">
                  Welcome To Medocly
                </span>
                <h1 className="text-white">
                  <span>Convenient Healthcare</span> At Your Fingertips
                </h1>
                <p className="lead">
                  Best Doctors in Jammu, Rajouri, Samba,Kathua, Udhampur,Srinagar,Kupwara,Pulwama, Shopian,
                  Gulmarg, Poonch, Anantnag, Kistwar, Doda, Jammu and kashmir, India . Book Doctor's
                  Appointment Online, View Fees, User feedbacks, Address & Phone Numbers of doctorss in INDIA
                  | Medocly                </p>

                <Link to="https://play.google.com/store/apps/details?id=com.medocly.patients" target="_blank"><button className="btn app-store-btn" >
                  Book Appointment</button></Link>
                <Link to="https://play.google.com/store/apps/details?id=com.medocly.doctors" target="_blank"><button className="btn app-store-btn " style={{ cursor: "pointer",marginLeft :"10px" }} >
                  For Doctors</button></Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
                <img
                  src="assets/img/frame1.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <Counter />
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Book Appointment</h5>
               
              </div>
              <div class="modal-body text-center">
               Coming Soon...
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">For Doctors</h5>
                
              </div>
              <div class="modal-body text-center">
                Coming Soon..
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                
              </div>
            </div>
          </div>
        </div>
      
      </section>
    </>
  );
}
