import Footer from "../../components/layout/footer/Footer";
import AboutApp from "../../components/about/AboutApp";
import Homecare from "../../components/about/Homecare"
import AccordionWithImg from "../../components/Faqs/AccordionWithImg";
import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import PopUp from "../../components/contact/Popup";
import FeatureImg from "../../components/features/FeatureImg";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Price from "../../components/prices/Price";
import PromoTwo from "../../components/promo/PromoTwo";
import Advantages from "../../components/promo/Advantages"
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import TeamTwo from "../../components/team/TeamTwo";
import HeroTwo from "./HeroTwo";
import Subsribe from "../../components/newsletter/Subsribe";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import TestimonialTwo from "../../components/testimonial/TestimonialTwo";
import Screenshot from "../../components/screenshot/Screenshot";
import { useEffect, useState } from "react";
import Download from "../../pages/Download";
import Download2 from "../../components/Download";

export default function HomeTwo() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('visitedBefore');
    if (!hasVisitedBefore) {
      setShowPopup(true);
      localStorage.setItem('visitedBefore', 'true');
    }
  }, []);
const openPopup =()=>{
  setShowPopup(true)
}
  const handlePopupClose = () => {
    setShowPopup(false);
  };
  return (
    <>
    <Layout>
      <Navbar darkBg />
      <HeroTwo />
      <Download2/>
      <PromoTwo />
    
     <Advantages/>
     <Homecare/>
      <AboutApp/>
      
      <FeatureImg ImgSource="assets/img/frame1.png" />
      
      {/* <VideoPromoTwo />
      <Price /> */}
      <TestimonialTwo />
      {/* <Screenshot hasBg /> */}
      <AccordionWithImg />
      {/* <LatestNewsOne light bgColor="gray-light-bg" />
      <TeamTwo /> */}
      <BrandCarousel />
      <Contact bgColor="gray-light-bg" />
      
      {/* <Subsribe /> */}
      <Footer space />
    </Layout>
    <i className="bi bi-chat-text-fill" 
    style={{position :"fixed",top:"85%",left :"95%",overflow :"visible",zIndex :"999",borderRadius:"15px",fontSize :"70px",color :"green",cursor:"pointer"}}
    onClick={openPopup}
    ></i>
    {showPopup===true && <PopUp onClose={handlePopupClose} />}

</>
  );
}
