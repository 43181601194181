import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-10">
              <div className="section-heading mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  <p className="text-center">
                  Key features
                  </p>
                  
                </span>
                <h2 className="mb-6 text-center">
                We help patients connect with doctors and facilitate convenient access to quality care
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-lg-0">
              <div className="card single-promo-card single-promo-hover">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-credit-card icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Easy healthcare at your fingertips</h5>
                    <p className="text-muted mb-0">
                    Skip the crowd and save time by booking your doctor consultation in advance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-control-record icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Doctor Reviews and Ratings</h5>
                    <p className="text-muted mb-0">
                    Make informed decisions from reviews and ratings from other patients
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-vector icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Easy Appointment Booking</h5>
                    <p className="text-muted mb-0">
                    Our application streamlines the appointment booking process for patients
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>On-Site Consultations</h5>
                    <p className="text-muted mb-0">
                    Patients have full flexibility to book the clinic or hospital for their consultations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
    </>
  );
}
