import React, { useState } from "react";
import FileViewer from 'react-file-viewer';
import termPdf from "../../../../src/Terms.pdf"
import privPdf from "../../../../src/PrivacyPolicy.pdf"
import RefundPolicy from "../../../../src/Refund Policy.pdf"
import { Link } from "react-router-dom";
export default function Footer({ space }) {
  const [docxHtml, setDocxHtml] = useState(null);
  const [docxHtml2, setDocxHtml2] = useState(null);
  // const termPdf ="http://74.208.105.240/download/1689838901426TERMANDConditions.pdf"
  //const privPdf= "http://74.208.105.240/download/1689839076947PRIVACYANDPOLICYMEDOCLY.pdf"
 // const patientRightsPdf ="http://74.208.105.240/download/1689839104520Patientrightsandresponsibilities.pdf"
//  function openDocx1(){
//   // Replace 'path/to/your/docx.docx' with the actual path to your local DOCX file.
  

  
//     const result =  mammoth.convertToHtml({ path: termPdf });
//     setDocxHtml(result.value);
  
// }
// function openDocx2(){
//   // Replace 'path/to/your/docx.docx' with the actual path to your local DOCX file.
  

  
//     const result =  mammoth.convertToHtml({ path: privPdf });
//     setDocxHtml2(result.value);
  
// };
  
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "url('assets/img/footer.png') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logowhite.png"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                  Medocly: A mobile application for convenient healthcare at your fingertips. Easily book doctor consultations at our clinics,
saving you time and ensuring accessible and patient-centered care.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/profile.php?id=100092979134359" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/medocly" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.instagram.com/medocly/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      <p>
                        <a style={{cursor :"pointer"}}  className="mt-3 text-light">Patient Rights and Responsibility</a>
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">Help</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Events</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Live Support</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Open Sources</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Documentation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#about">About Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Careers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Customers</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Community</a>
                        </li>
                        <li className="mb-2">
                          <a href="#team">Our Team</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-4 col-lg-4"></div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          Rehari, jammu, jammu and kashmir, 180005
                          <br />
                         
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel:+91 95969 99243"> +91 95969 99243 , +91 95411 81300</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:customer@medocly.com">
                            {" "}
                            customer@medocly.com
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="http://medocly.com/" target="_blank">medocly.com</a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <Link style={{cursor :"pointer"}} to="/terms" target="_blank">Terms & Conditions</Link>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <Link style={{cursor :"pointer"}} to="/privacy"  target="_blank">Privacy Policy</Link>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <Link style={{cursor :"pointer"}} to="/refund" target="_blank">Refund Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © Medocly, All rights reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item text-light">
                      Crafted at: 
                      </li>
                      
                      <li className="list-inline-item">
                        <a className="small-text" href="http://brainboxapps.com" target="_blank">
                        BrainBox Apps
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
