import React from "react";

export default function PromoTwo() {
  const data= [
    "Care Supervised by Doctors",
    "Book Doctor Cansultation from Home",
    "Find Near By Hospital",
    "Find Near By Doctors",
    "24 x 7 Nursing Care",
    "Medication Plans",
    "1:1 Care & Attention",
    "Measurable Outcome for Post-operative Care",
    "Personalized Care Plans"
  ]
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-10">
              <div className="section-heading mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  <p className="text-center">
                  Advantages
                  </p>
                  
                </span>
                <h2 className="mb-6 text-center">
                WHY MEDOCLY FOR YOUR LOVED ONES
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-lg-0">
              <div className="card single-promo-card single-promo-hover">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-credit-card icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Care Supervised by Doctors</h5>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-control-record icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Book Doctor Cansultation</h5>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-vector icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Find Near By Hospital</h5>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 ">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Find Near By <br/>Doctors</h5>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-lg-0 mt-3">
              <div className="card single-promo-card single-promo-hover">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-credit-card icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>24 x 7 Nursing <br/> Care</h5>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-control-record icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Medication Plans <br/> .</h5>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-vector icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>1:1 Care & <br/> Attention</h5>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5> Outcome for Post-operative Care</h5>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt-3">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Personalized Care Plans</h5>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
    </>
  );
}
