import React, { useState } from "react";
import { stateData } from '../../data'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
export default function Contact({ bgColor }) {
  const [name, setName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setPhone] = useState("")
  const [state, setState] = useState("")
  const [msg, setMsg] = useState("")
let navigate=  useNavigate()
  function handleSubmit(e) {
    e.preventDefault()
    const formData = { name, email, phone, state, msg }
    fetch('http://74.208.105.240/sent-email', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:JSON.stringify(formData)
        }).then(res=>{return res.json()})
        .then(response=>{
          if(response.status==true){
            toast('Email Sent Successfully')
            // navigate('/')
          }
          
        })
  }

  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
        <ToastContainer />
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                {/* <h2>Coming Soon..</h2> */}
                <p>
                  Please fill out this form to get in touch with us. We will send you an alert whenever we are ready. Also the early subscribers will get initial discounts and offers
                </p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Head Office</strong>
                </h6>
                <p>Rehari, jammu, jammu and kashmir, 180005</p>
                <ul>
                  <li>
                    <span>Phone: <a href={`https://api.whatsapp.com/send?phone=+9195969 99243`} target="_blank">+91 95969 99243</a> , <a href={`https://api.whatsapp.com/send?phone=+9195411 81300`} target="_blank">+91 95411 81300</a></span>
                  </li>
                  <li>
                    <span>
                      Email :
                      <a href="customer@medocly.com">
                        customer@medocly.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
                onSubmit={(e) => { handleSubmit(e) }}
              >
                <h5>Fill this form to get in touch with us</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => { setemail(e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        
                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      {/* <input
                        type="text"
                        name="company"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder="Your Company"
                      /> */}
                      <select className="form-control" aria-label="Default select example" name="company"
                        size=""
                        id="company"
                        value={state}
                        onChange={(e) => { setState(e.target.value) }}
                      >
                        <option >Select State</option>
                        {stateData.map((itm,key) => (
                          <option value={itm} key={key}>{itm}</option>
                        ))}

                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                        value={msg}
                        onChange={(e) => { setMsg(e.target.value) }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
