import React, { useState } from "react";
import { stateData } from '../../data'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
export default function PopUp({ onClose }) {
  const [name, setName] = useState("")
  const [email, setemail] = useState("")
  const [phone, setPhone] = useState("")
  const [state, setState] = useState("")
  const [msg, setMsg] = useState("")
let navigate=  useNavigate()
  function handleSubmit(e) {
    e.preventDefault()
    const formData = { name, email, phone, state, msg }
    fetch('http://74.208.105.240/sent-email', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:JSON.stringify(formData)
        }).then(res=>{return res.json()})
        .then(response=>{
          if(response.status==true){
            toast('Email Sent Successfully')
            // navigate('/')
            onClose()
          }else{
            toast.error("incorrect email")
          }
          
        })
  }

  return (
    <>
      <section
        style={{position :"fixed",top:"15%",left :"30%",overflow :"visible",background:"orange",width :"700px",zIndex :"999",borderRadius:"15px"}}
      >
        <div className="container">
        <ToastContainer />
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <button className="close-button btn  mt-1 " onClick={onClose}
            style={{borderRadius :"52%"}}
            >
      <i class="bi bi-x-lg fs-1"></i>
      </button>
            <div className="col-md-12">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
                onSubmit={(e) => { handleSubmit(e) }}
              >
                <h5>Fill this form to get in touch with us</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => { setemail(e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        
                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      {/* <input
                        type="text"
                        name="company"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder="Your Company"
                      /> */}
                      <select className="form-control" aria-label="Default select example" name="company"
                        size=""
                        id="company"
                        value={state}
                        onChange={(e) => { setState(e.target.value) }}
                      >
                        <option >Select State</option>
                        {stateData.map((itm,key) => (
                          <option value={itm} key={key}>{itm}</option>
                        ))}

                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                        value={msg}
                        onChange={(e) => { setMsg(e.target.value) }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn btn-dark"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
