import HeroTwo from "../../themes/index-2/HeroTwo";
import Layout from "../layout/Layout";
import Footer from "../layout/footer/Footer";
import Navbar from "../layout/header/Navbar";

function Refund() {
    return ( 
        <>
        <Layout>
        <Navbar darkBg/>
        <HeroTwo/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h2 className="text-center">Refund Policy</h2>
                <p className="text-center text-dark fs-1">**Medocly Healthcare Private Limited - Refund Policy**
**Last Updated: [03/10/2023]**
Thank you for choosing Medocly Healthcare Private Limited. We are committed to providing you with
exceptional healthcare services. However, we understand that there may be situations where a refund is
necessary. Please read this refund policy carefully before using our services.
**1. Eligibility for Refund**
- Medocly Healthcare Private Limited will consider refund requests under the following conditions:
 - The request is made within 1 days of the original service or appointment date.
 - The service or appointment meets the criteria outlined in our service-specific policies (if applicable).
 - The request complies with the terms and conditions of this policy.
**2. Services Not Eligible for Refund**
- Certain services may not be eligible for a refund. These include, but are not limited to, services that
have been fully rendered, digital products, and personalized services.
**3. Refund Process**
- To initiate a refund request, please contact our customer support team at [customer@medocly.com].
- Provide your appointment or service details, order number, contact information, and a detailed
explanation of the reason for the refund request.
- We will review your request and respond within [10] business days to inform you of the status of your
refund.
- If approved, the refund will be processed via the original payment method used for the service or
appointment. Please allow [15] business days for the refund to reflect in your account.
**4. Cancellations**
"If you wish to cancel an appointment or service before the scheduled date or up to 2 hours before the
appointment time, please contact us immediately. Depending on the status of the appointment or
service, we will either process a refund or cancel the transaction."
**5. Contact Us**
- If you have any questions or need further assistance regarding our refund policy, please contact our
customer support team at [customer@medocly.com].
**6. Changes to the Refund Policy**
- Medocly Healthcare Private Limited reserves the right to modify or update this refund policy at any
time. Any changes will be effective immediately upon posting on our website.
By using our services, you acknowledge that you have read, understood, and agreed to our refund
policy.
[Medocly Healthcare pvt ltd]
[Jammu]
[customer@medocly.com]</p>
                </div>
            </div>
        </div>
        <Footer/>
        </Layout>
        </>
     );
}

export default Refund;