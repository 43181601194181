export const reviewData = [
  {
    id: 1,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-1.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 2,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-2.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 3,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-3.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 4,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-4.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 5,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-5.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 6,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-2.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
];

export const blogData = [
  {
    id: 1,
    category: "Lifstyle",
    thumbnail: "assets/img/blog/1.jpg",
    date: "Jan 21, 2019",
    comments: "45",
    share: "10",
    title: "Appropriately productize fully",
    excerpt:
      "Some quick example text to build on the card title and make up the bulk.",
  },
  {
    id: 2,
    category: "Technology",
    thumbnail: "assets/img/blog/2.jpg",
    date: "Jan 21, 2019",
    comments: "45",
    share: "10",
    title: "Quickly formulate backend",
    excerpt:
      "Synergistically engage effective ROI after customer directed partnerships",
  },
  {
    id: 3,
    category: "Science",
    thumbnail: "assets/img/blog/3.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 4,
    category: "Science",
    thumbnail: "assets/img/blog/4.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 5,
    category: "Science",
    thumbnail: "assets/img/blog/5.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
  {
    id: 6,
    category: "Science",
    thumbnail: "assets/img/blog/6.jpg",
    date: "April 26, 2019",
    comments: "41",
    share: "30",
    title: "Objectively extend extensive",
    excerpt:
      "Holisticly mesh open-source leadership rather than proactive users",
  },
];

export const stateData =[
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
]

export const Faqs= [
  {
    "question":"How do I book a physical consultation with a doctor?",
    "answer":"To book a physical consultation, you can visit our website or MEDOCLY application to schedule an appointment at the preferred Time and clinic location."
  },
  {
    "question":"What are the clinic locations where I can have a consultation?",
    "answer":"We have multiple clinic locations conveniently situated across the city. You can find the list of clinics MEDOCLY application or inquire with our customer support team."
  },
  {
    "question":"Can I choose a specific doctor for my physical consultation?",
    "answer":"Yes, you can choose a specific doctor from our list of healthcare providers when booking a physical consultation."
  },
  {
    "question":"How early should I arrive for my appointment?",
    "answer":"We recommend arriving at least 15 minutes before your scheduled appointment time to complete any necessary paperwork and registration."
  },
  {
    "question":"What should I bring to the consultation?",
    "answer":"Please bring your mobile because we need OTP after cansultation complete , any previous medical records or prescriptions, and any relevant health insurance details for verification."
  },
  {
    "question":"Is there any waiting time for the consultation?",
    "answer":"While we strive to maintain punctuality, there might be some waiting time due to unforeseen circumstances or emergencies. We appreciate your patience and assure you of quality care."
  },
  {
    "question":"Can I reschedule or cancel my physical consultation appointment?",
    "answer":"Yes, you can reschedule or cancel your appointment by contacting our customer support team. Please inform us in advance to allow us to accommodate other patients."
  },
  {
    "question":"Can I request a follow-up appointment after my initial consultation?",
    "answer":"Yes, after your initial consultation, you can schedule a follow-up appointment based on your doctor's recommendations."
  },
  {
    "question":"How can I request home care nursing services?",
    "answer":"To request home care nursing services, you can visit MEDOCLY application or contact our customer support team through our website, email Customer@medocly.com or phone, and they will guide you through the process."
  },
  {
    "question":"What types of nursing services are available for home care?",
    "answer":"We offer a comprehensive range of nursing services at home, including wound dressing, medication administration, injections, catheter care, and assistance with activities of daily living."
  },
  {
    "question":"Are the nurses providing home care licensed and experienced?",
    "answer":"Yes, all our home care nurses are licensed, certified, and experienced professionals who undergo rigorous training to ensure quality care."
  },
  {
    "question":"Can I request specific nursing procedures or treatments for home care?",
    "answer":"Yes, you can discuss your specific nursing care requirements with our team, and we will ensure that the appropriate procedures and treatments are provided by qualified nurses."
  },
  {
    "question":"Is home care nursing available on weekends and holidays?",
    "answer":"Yes, we provide home care nursing services seven days a week, including weekends and holidays, to cater to your healthcare needs."
  }
]